import { Application } from '../application';

var ProfileNagEdit = (function($) {
  function make_form_editable(_event) {
    $('.section-edit-button').trigger('click');
    $('#control-buttons').hide();
    $('#save-and-continue-profile-verification').show();
    $('#profile-verification-cancel').show();
  }
  function setup_edit_all_button() {
    $('#profile-verification-edit').on('click', make_form_editable);
  }
  function hide_edit_buttons() {
    $('.section-edit-button').hide();
  }

  function hasOnlyCertificationError() {
    const errors = $('#profile-nag-errors li');
    return errors.length === 0;
  }

  function setup() {
    $(function () {
      if (Application.current({
        controller: 'user_profile_verifications'
      })) {
        hide_edit_buttons();
        setup_edit_all_button();
      }
      if (Application.current({
        controller: 'user_profile_verifications',
        action: 'update'
      })) {
        if (!hasOnlyCertificationError()) {
          $('#profile-verification-edit').trigger('click');
          make_form_editable();
        }
      }
    });
  }
  return {
    setup: setup
  };
}(jQuery));

ProfileNagEdit.setup();
